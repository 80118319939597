











































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'Chem51LBProject1FS2QuantitativeObservations',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        rowsRf: [
          {
            tlcPlate: '',
            spotLabel: '',
            rfValue: '',
            commentRf: '',
          },
        ],
        rowsMP: [
          {
            compound: '',
            meltingRange: '',
            commentMP: '',
          },
        ],
        additionalObservations: '',
      },
      columnsRf: [
        {
          label: 'TLC Plate',
          component: STextareaShort,
          prop: 'tlcPlate',
          width: '15%',
        },
        {
          label: 'Spot Label',
          component: STextareaShort,
          prop: 'spotLabel',
          width: '20%',
        },
        {
          label: 'Rf Value',
          component: STextareaShort,
          prop: 'rfValue',
          width: '20%',
        },
        {
          label: 'Comments',
          component: STextareaShort,
          prop: 'commentRf',
          width: '45%',
        },
      ],
      columnsMP: [
        {
          label: 'Compound',
          component: STextareaShort,
          prop: 'compound',
          width: '29%',
        },
        {
          label: 'Melting Range (°C)',
          component: STextareaShort,
          prop: 'meltingRange',
          width: '26%',
        },
        {
          label: 'Comments',
          component: STextareaShort,
          prop: 'commentMP',
          width: '45%',
        },
      ],
      attachments: [] as File[],
    };
  },
  methods: {},
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
